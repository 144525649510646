import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Project from "../components/project"
import { graphql } from "gatsby"

export default ({ data }) => {
  const projects = data.allMarkdownRemark.edges

  return (
    <Layout>
      <SEO title="Tom Price" />
      <div className="jumbotron jumbotron-fluid code-banner p-6"></div>

      <div className="container">
        <div className="row">
          <div className="col-xs-12">
            <h1>About Me</h1>
            <p>I'm a Software Engineer who has been working in the industry since 2012 but have been programming for over 12 years. I mainly use JavaScript for work and personal projects but also have experience using languages such as Java, and PHP.</p>

            <h2>Some Personal Projects...</h2>

            <div>
              <ul className="list-unstyled row projects">
                {projects.sort((a, b) =>  a.node.frontmatter.priority - b.node.frontmatter.priority).map(({ node: project }, i) => (
                  <li key={i} className="col-sm-4 col-lg-3 mx-2">
                    <Project {...project} />
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query {
    allMarkdownRemark {
      edges {
        node {
          frontmatter {
            title
            description
            intro
            priority
            previewImage {
              childImageSharp {
                fluid(maxWidth: 800) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          fields {
            slug
          }
        }
      }
    }
  }
`
