import React from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"

export default function Project(project) {
  const data = project.frontmatter
  return (
    <Link to={project.fields.slug}>
      <div className="p-2">
        <h2>{data.title}</h2>
        {data.previewImage && <Img fluid={data.previewImage.childImageSharp.fluid} alt={`${data.title} preview`} />}
        <p>{data.intro}</p>
      </div>
    </Link>
  )
}
